/**
 * Storage permissions.
 *
 * @id - Used to identify the rule on permissions and upload.
 * @folder - Folder where the files will be saved
 * @maxSizeInBytes - Max allowed size in bytes
 * @bypassWritingPermissions - Does not validate if the user has permission to write
 * @publicRead - The file can be publicly accessed via the URL without the need for a signed token
 */
export default class Storage {
  static get values() {
    return {
      assetsIcon: {
        id: 'assetsIcon',
        folder: 'tenant/:tenantId/assets/icon',
        maxSizeInBytes: 100 * 1024 * 1024,
        publicRead: true,
      },

      assetsTypeIcon: {
        id: 'assetsTypeIcon',
        folder: 'tenant/:tenantId/assetsType/icon',
        maxSizeInBytes: 100 * 1024 * 1024,
        publicRead: true,
      },

      firmwarePackage: {
        id: 'firmwarePackage',
        folder: 'tenant/:tenantId/firmware/package',
        maxSizeInBytes: 100 * 1024 * 1024,
        publicRead: true,
      },

      settingsLogos: {
        id: 'settingsLogos',
        folder: 'tenant/:tenantId/settings/logos',
        maxSizeInBytes: 10 * 1024 * 1024,
        publicRead: true,
      },
      
      settingsBackgroundImages: {
        id: 'settingsBackgroundImages',
        folder:
          'tenant/:tenantId/settings/backgroundImages',
        maxSizeInBytes: 10 * 1024 * 1024,
        publicRead: true,
      },

      userAvatarsProfiles: {
        id: 'userAvatarsProfiles',
        folder: 'user/avatars/profile/:userId',
        maxSizeInBytes: 10 * 1024 * 1024,
        bypassWritingPermissions: true,
        publicRead: true,
      },
    };
  }
}
