import Permissions from 'src/security/permissions';
import config from 'src/config';
const permissions = Permissions.values;

const privateRoutes = [
  // {
  //   path: '/',
  //   loader: () =>
  //     import('src/view/dashboard/DashboardPage'),
  //   permissionRequired: null,
  //   exact: true,
  // },
  {
    path: '/',
    loader: () =>
      import('src/view/dashboards/DashboardPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/profile',
    loader: () => import('src/view/auth/ProfileFormPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/password-change',
    loader: () =>
      import('src/view/auth/PasswordChangeFormPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/tenant',
    loader: () =>
      import('src/view/tenant/list/TenantListPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/tenant/new',
    loader: () =>
      import('src/view/tenant/form/TenantFormPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/tenant/:id/edit',
    loader: () =>
      import('src/view/tenant/form/TenantFormPage'),
    permissionRequired: null,
    exact: true,
  },

  config.isPlanEnabled && {
    path: '/plan',
    loader: () => import('src/view/plan/PlanPage'),
    permissionRequired: permissions.planRead,
    exact: true,
  },

  {
    path: '/user',
    loader: () => import('src/view/user/list/UserPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  {
    path: '/user/new',
    loader: () => import('src/view/user/new/UserNewPage'),
    permissionRequired: permissions.userCreate,
    exact: true,
  },

  {
    path: '/user/importer',
    loader: () =>
      import('src/view/user/importer/UserImporterPage'),
    permissionRequired: permissions.userImport,
    exact: true,
  },
  {
    path: '/user/:id/edit',
    loader: () => import('src/view/user/edit/UserEditPage'),
    permissionRequired: permissions.userEdit,
    exact: true,
  },
  {
    path: '/user/:id',
    loader: () => import('src/view/user/view/UserViewPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  {
    path: '/audit-logs',
    loader: () => import('src/view/auditLog/AuditLogPage'),
    permissionRequired: permissions.auditLogRead,
  },

  {
    path: '/settings',
    loader: () =>
      import('src/view/settings/SettingsFormPage'),
    permissionRequired: permissions.settingsEdit,
  },
  
  // {
  //   path: '/locations',
  //   loader: () =>
  //     import('src/view/locations/list/LocationsListPage'),
  //   permissionRequired: permissions.locationsRead,
  //   exact: true,
  // },
  // {
  //   path: '/locations/new',
  //   loader: () =>
  //     import('src/view/locations/form/LocationsFormPage'),
  //   permissionRequired: permissions.locationsCreate,
  //   exact: true,
  // },
  // {
  //   path: '/locations/importer',
  //   loader: () =>
  //     import(
  //       'src/view/locations/importer/LocationsImporterPage'
  //     ),
  //   permissionRequired: permissions.locationsImport,
  //   exact: true,
  // },
  // {
  //   path: '/locations/:id/edit',
  //   loader: () =>
  //     import('src/view/locations/form/LocationsFormPage'),
  //   permissionRequired: permissions.locationsEdit,
  //   exact: true,
  // },
  // {
  //   path: '/locations/:id',
  //   loader: () =>
  //     import('src/view/locations/view/LocationsViewPage'),
  //   permissionRequired: permissions.locationsRead,
  //   exact: true,
  // },

  // {
  //   path: '/buildings',
  //   loader: () =>
  //     import('src/view/buildings/list/BuildingsListPage'),
  //   permissionRequired: permissions.buildingsRead,
  //   exact: true,
  // },
  // {
  //   path: '/buildings/new',
  //   loader: () =>
  //     import('src/view/buildings/form/BuildingsFormPage'),
  //   permissionRequired: permissions.buildingsCreate,
  //   exact: true,
  // },
  // {
  //   path: '/buildings/importer',
  //   loader: () =>
  //     import(
  //       'src/view/buildings/importer/BuildingsImporterPage'
  //     ),
  //   permissionRequired: permissions.buildingsImport,
  //   exact: true,
  // },
  // {
  //   path: '/buildings/:id/edit',
  //   loader: () =>
  //     import('src/view/buildings/form/BuildingsFormPage'),
  //   permissionRequired: permissions.buildingsEdit,
  //   exact: true,
  // },
  // {
  //   path: '/buildings/:id',
  //   loader: () =>
  //     import('src/view/buildings/view/BuildingsViewPage'),
  //   permissionRequired: permissions.buildingsRead,
  //   exact: true,
  // },

  // {
  //   path: '/floors',
  //   loader: () =>
  //     import('src/view/floors/list/FloorsListPage'),
  //   permissionRequired: permissions.floorsRead,
  //   exact: true,
  // },
  // {
  //   path: '/floors/new',
  //   loader: () =>
  //     import('src/view/floors/form/FloorsFormPage'),
  //   permissionRequired: permissions.floorsCreate,
  //   exact: true,
  // },
  // {
  //   path: '/floors/importer',
  //   loader: () =>
  //     import(
  //       'src/view/floors/importer/FloorsImporterPage'
  //     ),
  //   permissionRequired: permissions.floorsImport,
  //   exact: true,
  // },
  // {
  //   path: '/floors/:id/edit',
  //   loader: () =>
  //     import('src/view/floors/form/FloorsFormPage'),
  //   permissionRequired: permissions.floorsEdit,
  //   exact: true,
  // },
  // {
  //   path: '/floors/:id',
  //   loader: () =>
  //     import('src/view/floors/view/FloorsViewPage'),
  //   permissionRequired: permissions.floorsRead,
  //   exact: true,
  // },

  {
    path: '/asset-types',
    loader: () =>
      import('src/view/assetTypes/list/AssetTypesListPage'),
    permissionRequired: permissions.assetTypesRead,
    exact: true,
  },
  {
    path: '/asset-types/new',
    loader: () =>
      import('src/view/assetTypes/form/AssetTypesFormPage'),
    permissionRequired: permissions.assetTypesCreate,
    exact: true,
  },
  {
    path: '/asset-types/importer',
    loader: () =>
      import(
        'src/view/assetTypes/importer/AssetTypesImporterPage'
      ),
    permissionRequired: permissions.assetTypesImport,
    exact: true,
  },
  {
    path: '/asset-types/:id/edit',
    loader: () =>
      import('src/view/assetTypes/form/AssetTypesFormPage'),
    permissionRequired: permissions.assetTypesEdit,
    exact: true,
  },
  {
    path: '/asset-types/:id',
    loader: () =>
      import('src/view/assetTypes/view/AssetTypesViewPage'),
    permissionRequired: permissions.assetTypesRead,
    exact: true,
  },

  // {
  //   path: '/asset-sub-types',
  //   loader: () =>
  //     import('src/view/assetSubTypes/list/AssetSubTypesListPage'),
  //   permissionRequired: permissions.assetSubTypesRead,
  //   exact: true,
  // },
  // {
  //   path: '/asset-sub-types/new',
  //   loader: () =>
  //     import('src/view/assetSubTypes/form/AssetSubTypesFormPage'),
  //   permissionRequired: permissions.assetSubTypesCreate,
  //   exact: true,
  // },
  // {
  //   path: '/asset-sub-types/importer',
  //   loader: () =>
  //     import(
  //       'src/view/assetSubTypes/importer/AssetSubTypesImporterPage'
  //     ),
  //   permissionRequired: permissions.assetSubTypesImport,
  //   exact: true,
  // },
  // {
  //   path: '/asset-sub-types/:id/edit',
  //   loader: () =>
  //     import('src/view/assetSubTypes/form/AssetSubTypesFormPage'),
  //   permissionRequired: permissions.assetSubTypesEdit,
  //   exact: true,
  // },
  // {
  //   path: '/asset-sub-types/:id',
  //   loader: () =>
  //     import('src/view/assetSubTypes/view/AssetSubTypesViewPage'),
  //   permissionRequired: permissions.assetSubTypesRead,
  //   exact: true,
  // },

  // {
  //   path: '/telemetry-keys',
  //   loader: () =>
  //     import('src/view/telemetryKeys/list/TelemetryKeysListPage'),
  //   permissionRequired: permissions.telemetryKeysRead,
  //   exact: true,
  // },
  // {
  //   path: '/telemetry-keys/new',
  //   loader: () =>
  //     import('src/view/telemetryKeys/form/TelemetryKeysFormPage'),
  //   permissionRequired: permissions.telemetryKeysCreate,
  //   exact: true,
  // },
  // {
  //   path: '/telemetry-keys/importer',
  //   loader: () =>
  //     import(
  //       'src/view/telemetryKeys/importer/TelemetryKeysImporterPage'
  //     ),
  //   permissionRequired: permissions.telemetryKeysImport,
  //   exact: true,
  // },
  // {
  //   path: '/telemetry-keys/:id/edit',
  //   loader: () =>
  //     import('src/view/telemetryKeys/form/TelemetryKeysFormPage'),
  //   permissionRequired: permissions.telemetryKeysEdit,
  //   exact: true,
  // },
  // {
  //   path: '/telemetry-keys/:id',
  //   loader: () =>
  //     import('src/view/telemetryKeys/view/TelemetryKeysViewPage'),
  //   permissionRequired: permissions.telemetryKeysRead,
  //   exact: true,
  // },

  // {
  //   path: '/sensors',
  //   loader: () =>
  //     import('src/view/sensors/list/SensorsListPage'),
  //   permissionRequired: permissions.sensorsRead,
  //   exact: true,
  // },
  // {
  //   path: '/sensors/new',
  //   loader: () =>
  //     import('src/view/sensors/form/SensorsFormPage'),
  //   permissionRequired: permissions.sensorsCreate,
  //   exact: true,
  // },
  // {
  //   path: '/sensors/importer',
  //   loader: () =>
  //     import(
  //       'src/view/sensors/importer/SensorsImporterPage'
  //     ),
  //   permissionRequired: permissions.sensorsImport,
  //   exact: true,
  // },
  // {
  //   path: '/sensors/:id/edit',
  //   loader: () =>
  //     import('src/view/sensors/form/SensorsFormPage'),
  //   permissionRequired: permissions.sensorsEdit,
  //   exact: true,
  // },
  // {
  //   path: '/sensors/:id',
  //   loader: () =>
  //     import('src/view/sensors/view/SensorsViewPage'),
  //   permissionRequired: permissions.sensorsRead,
  //   exact: true,
  // },

  {
    path: '/assets',
    loader: () =>
      import('src/view/assets/list/AssetsListPage'),
    permissionRequired: permissions.assetsRead,
    exact: true,
  },
  {
    path: '/assets/new',
    loader: () =>
      import('src/view/assets/form/AssetsFormPage'),
    permissionRequired: permissions.assetsCreate,
    exact: true,
  },
  {
    path: '/assets/importer',
    loader: () =>
      import(
        'src/view/assets/importer/AssetsImporterPage'
      ),
    permissionRequired: permissions.assetsImport,
    exact: true,
  },
  {
    path: '/assets/:id/edit',
    loader: () =>
      import('src/view/assets/form/AssetsFormPage'),
    permissionRequired: permissions.assetsEdit,
    exact: true,
  },
  {
    path: '/assets/:id',
    loader: () =>
      import('src/view/assets/view/AssetsViewPage'),
    permissionRequired: permissions.assetsRead,
    exact: true,
  },

  // {
  //   path: '/orders',
  //   loader: () =>
  //     import('src/view/orders/list/OrdersListPage'),
  //   permissionRequired: permissions.ordersRead,
  //   exact: true,
  // },
  // {
  //   path: '/orders/new',
  //   loader: () =>
  //     import('src/view/orders/form/OrdersFormPage'),
  //   permissionRequired: permissions.ordersCreate,
  //   exact: true,
  // },
  // {
  //   path: '/orders/importer',
  //   loader: () =>
  //     import(
  //       'src/view/orders/importer/OrdersImporterPage'
  //     ),
  //   permissionRequired: permissions.ordersImport,
  //   exact: true,
  // },
  // {
  //   path: '/orders/:id/edit',
  //   loader: () =>
  //     import('src/view/orders/form/OrdersFormPage'),
  //   permissionRequired: permissions.ordersEdit,
  //   exact: true,
  // },
  // {
  //   path: '/orders/:id',
  //   loader: () =>
  //     import('src/view/orders/view/OrdersViewPage'),
  //   permissionRequired: permissions.ordersRead,
  //   exact: true,
  // },

  // {
  //   path: '/orderItems',
  //   loader: () =>
  //     import('src/view/orderItems/list/OrderItemsListPage'),
  //   permissionRequired: permissions.orderItemsRead,
  //   exact: true,
  // },
  // {
  //   path: '/orderItems/new',
  //   loader: () =>
  //     import('src/view/orderItems/form/OrderItemsFormPage'),
  //   permissionRequired: permissions.orderItemsCreate,
  //   exact: true,
  // },
  // {
  //   path: '/orderItems/importer',
  //   loader: () =>
  //     import(
  //       'src/view/orderItems/importer/OrderItemsImporterPage'
  //     ),
  //   permissionRequired: permissions.orderItemsImport,
  //   exact: true,
  // },
  // {
  //   path: '/orderItems/:id/edit',
  //   loader: () =>
  //     import('src/view/orderItems/form/OrderItemsFormPage'),
  //   permissionRequired: permissions.orderItemsEdit,
  //   exact: true,
  // },
  // {
  //   path: '/orderItems/:id',
  //   loader: () =>
  //     import('src/view/orderItems/view/OrderItemsViewPage'),
  //   permissionRequired: permissions.orderItemsRead,
  //   exact: true,
  // },

  {
    path: '/firmwares',
    loader: () =>
      import('src/view/firmwares/list/FirmwaresListPage'),
    permissionRequired: permissions.firmwaresRead,
    exact: true,
  },
  {
    path: '/firmwares/new',
    loader: () =>
      import('src/view/firmwares/form/FirmwaresFormPage'),
    permissionRequired: permissions.firmwaresCreate,
    exact: true,
  },
  {
    path: '/firmwares/importer',
    loader: () =>
      import(
        'src/view/firmwares/importer/FirmwaresImporterPage'
      ),
    permissionRequired: permissions.firmwaresImport,
    exact: true,
  },
  {
    path: '/firmwares/:id/edit',
    loader: () =>
      import('src/view/firmwares/form/FirmwaresFormPage'),
    permissionRequired: permissions.firmwaresEdit,
    exact: true,
  },
  {
    path: '/firmwares/:id',
    loader: () =>
      import('src/view/firmwares/view/FirmwaresViewPage'),
    permissionRequired: permissions.firmwaresRead,
    exact: true,
  },

  {
    path: '/devices',
    loader: () =>
      import('src/view/devices/list/DevicesListPage'),
    permissionRequired: permissions.devicesRead,
    exact: true,
  },
  {
    path: '/devices/new',
    loader: () =>
      import('src/view/devices/form/DevicesFormPage'),
    permissionRequired: permissions.devicesCreate,
    exact: true,
  },
  {
    path: '/devices/importer',
    loader: () =>
      import(
        'src/view/devices/importer/DevicesImporterPage'
      ),
    permissionRequired: permissions.devicesImport,
    exact: true,
  },
  {
    path: '/devices/:id/edit',
    loader: () =>
      import('src/view/devices/form/DevicesFormPage'),
    permissionRequired: permissions.devicesEdit,
    exact: true,
  },
  {
    path: '/devices/:id',
    loader: () =>
      import('src/view/devices/view/DevicesViewPage'),
    permissionRequired: permissions.devicesRead,
    exact: true,
  },  

  {
    path: '/network-providers',
    loader: () =>
      import('src/view/networkProviders/list/NetworkProvidersListPage'),
    permissionRequired: permissions.networkProvidersRead,
    exact: true,
  },
  {
    path: '/network-providers/new',
    loader: () =>
      import('src/view/networkProviders/form/NetworkProvidersFormPage'),
    permissionRequired: permissions.networkProvidersCreate,
    exact: true,
  },
  {
    path: '/network-providers/importer',
    loader: () =>
      import(
        'src/view/networkProviders/importer/NetworkProvidersImporterPage'
      ),
    permissionRequired: permissions.networkProvidersImport,
    exact: true,
  },
  {
    path: '/network-providers/importerThingsMobile',
    loader: () =>
      import(
        'src/view/networkProviders/importer/NetworkProvidersImporterPage'
      ),
    permissionRequired: permissions.networkProvidersImport,
    exact: true,
  },
  {
    path: '/network-providers/:id/edit',
    loader: () =>
      import('src/view/networkProviders/form/NetworkProvidersFormPage'),
    permissionRequired: permissions.networkProvidersEdit,
    exact: true,
  },
  {
    path: '/network-providers/:id',
    loader: () =>
      import('src/view/networkProviders/view/NetworkProvidersViewPage'),
    permissionRequired: permissions.networkProvidersRead,
    exact: true,
  },  

  // {
  //   path: '/telemetry',
  //   loader: () =>
  //     import('src/view/telemetry/list/TelemetryListPage'),
  //   permissionRequired: permissions.telemetryRead,
  //   exact: true,
  // },
  // {
  //   path: '/telemetry/new',
  //   loader: () =>
  //     import('src/view/telemetry/form/TelemetryFormPage'),
  //   permissionRequired: permissions.telemetryCreate,
  //   exact: true,
  // },
  // {
  //   path: '/telemetry/importer',
  //   loader: () =>
  //     import(
  //       'src/view/telemetry/importer/TelemetryImporterPage'
  //     ),
  //   permissionRequired: permissions.telemetryImport,
  //   exact: true,
  // },
  // {
  //   path: '/telemetry/:id/edit',
  //   loader: () =>
  //     import('src/view/telemetry/form/TelemetryFormPage'),
  //   permissionRequired: permissions.telemetryEdit,
  //   exact: true,
  // },
  // {
  //   path: '/telemetry/:id',
  //   loader: () =>
  //     import('src/view/telemetry/view/TelemetryViewPage'),
  //   permissionRequired: permissions.telemetryRead,
  //   exact: true,
  // },

  {
    path: '/notifications',
    loader: () =>
      import('src/view/notifications/list/NotificationsListPage'),
    permissionRequired: permissions.notificationsRead,
    exact: true,
  },
  {
    path: '/notifications/new',
    loader: () =>
      import('src/view/notifications/form/NotificationsFormPage'),
    permissionRequired: permissions.notificationsCreate,
    exact: true,
  },
  {
    path: '/notifications/importer',
    loader: () =>
      import(
        'src/view/notifications/importer/NotificationsImporterPage'
      ),
    permissionRequired: permissions.notificationsImport,
    exact: true,
  },
  {
    path: '/notifications/:id/edit',
    loader: () =>
      import('src/view/notifications/form/NotificationsFormPage'),
    permissionRequired: permissions.notificationsEdit,
    exact: true,
  },
  {
    path: '/notifications/:id',
    loader: () =>
      import('src/view/notifications/view/NotificationsViewPage'),
    permissionRequired: permissions.notificationsRead,
    exact: true,
  },

  // {
  //   path: '/sensor-profile',
  //   loader: () =>
  //     import('src/view/sensorProfile/list/SensorProfileListPage'),
  //   permissionRequired: permissions.sensorProfileRead,
  //   exact: true,
  // },
  // {
  //   path: '/sensor-profile/new',
  //   loader: () =>
  //     import('src/view/sensorProfile/form/SensorProfileFormPage'),
  //   permissionRequired: permissions.sensorProfileCreate,
  //   exact: true,
  // },
  // {
  //   path: '/sensor-profile/importer',
  //   loader: () =>
  //     import(
  //       'src/view/sensorProfile/importer/SensorProfileImporterPage'
  //     ),
  //   permissionRequired: permissions.sensorProfileImport,
  //   exact: true,
  // },
  // {
  //   path: '/sensor-profile/:id/edit',
  //   loader: () =>
  //     import('src/view/sensorProfile/form/SensorProfileFormPage'),
  //   permissionRequired: permissions.sensorProfileEdit,
  //   exact: true,
  // },
  // {
  //   path: '/sensor-profile/:id',
  //   loader: () =>
  //     import('src/view/sensorProfile/view/SensorProfileViewPage'),
  //   permissionRequired: permissions.sensorProfileRead,
  //   exact: true,
  // },
  // {
  //   path: '/gateways',
  //   loader: () =>
  //     import('src/view/gateways/list/GatewaysListPage'),
  //   permissionRequired: permissions.gatewaysRead,
  //   exact: true,
  // },
  // {
  //   path: '/gateways/new',
  //   loader: () =>
  //     import('src/view/gateways/form/GatewaysFormPage'),
  //   permissionRequired: permissions.gatewaysCreate,
  //   exact: true,
  // },
  // {
  //   path: '/gateways/importer',
  //   loader: () =>
  //     import(
  //       'src/view/gateways/importer/GatewaysImporterPage'
  //     ),
  //   permissionRequired: permissions.gatewaysImport,
  //   exact: true,
  // },
  // {
  //   path: '/gateways/:id/edit',
  //   loader: () =>
  //     import('src/view/gateways/form/GatewaysFormPage'),
  //   permissionRequired: permissions.gatewaysEdit,
  //   exact: true,
  // },
  // {
  //   path: '/gateways/:id',
  //   loader: () =>
  //     import('src/view/gateways/view/GatewaysViewPage'),
  //   permissionRequired: permissions.gatewaysRead,
  //   exact: true,
  // },

  {
    path: '/user-permissions',
    loader: () =>
      import('src/view/userPermissions/list/UserPermissionsListPage'),
    permissionRequired: permissions.userPermissionsRead,
    exact: true,
  },
  {
    path: '/user-permissions/new',
    loader: () =>
      import('src/view/userPermissions/form/UserPermissionsFormPage'),
    permissionRequired: permissions.userPermissionsCreate,
    exact: true,
  },
  {
    path: '/user-permissions/importer',
    loader: () =>
      import(
        'src/view/userPermissions/importer/UserPermissionsImporterPage'
      ),
    permissionRequired: permissions.userPermissionsImport,
    exact: true,
  },
  {
    path: '/user-permissions/:id/edit',
    loader: () =>
      import('src/view/userPermissions/form/UserPermissionsFormPage'),
    permissionRequired: permissions.userPermissionsEdit,
    exact: true,
  },
  {
    path: '/user-permissions/:id',
    loader: () =>
      import('src/view/userPermissions/view/UserPermissionsViewPage'),
    permissionRequired: permissions.userPermissionsRead,
    exact: true,
  },

].filter(Boolean);

const publicRoutes = [
  {
    path: '/auth/signin',
    loader: () => import('src/view/auth/SigninPage'),
  },
  {
    path: '/auth/signup',
    loader: () => import('src/view/auth/SignupPage'),
  },
  {
    path: '/auth/forgot-password',
    loader: () =>
      import('src/view/auth/ForgotPasswordPage'),
  },
].filter(Boolean);

const emptyTenantRoutes = [
  {
    path: '/auth/tenant',
    loader: () => import('src/view/auth/TenantPage'),
  },
].filter(Boolean);

const emptyPermissionsRoutes = [
  {
    path: '/auth/empty-permissions',
    loader: () =>
      import('src/view/auth/EmptyPermissionsPage'),
  },
].filter(Boolean);

const emailUnverifiedRoutes = [
  {
    path: '/auth/email-unverified',
    loader: () =>
      import('src/view/auth/EmailUnverifiedPage'),
  },
].filter(Boolean);

const simpleRoutes = [
  {
    path: '/auth/password-reset',
    loader: () => import('src/view/auth/PasswordResetPage'),
  },
  {
    path: '/auth/invitation',
    loader: () => import('src/view/auth/InvitationPage'),
  },
  {
    path: '/auth/verify-email',
    loader: () => import('src/view/auth/VerifyEmailPage'),
  },
  {
    path: '/403',
    loader: () =>
      import('src/view/shared/errors/Error403Page'),
  },
  {
    path: '/500',
    loader: () =>
      import('src/view/shared/errors/Error500Page'),
  },
  {
    path: '**',
    loader: () =>
      import('src/view/shared/errors/Error404Page'),
  },
].filter(Boolean);

export default {
  privateRoutes,
  publicRoutes,
  emptyTenantRoutes,
  emptyPermissionsRoutes,
  emailUnverifiedRoutes,
  simpleRoutes,
};
