import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/networkProviders/importer/networkProvidersImporterSelectors';
import NetworkProvidersService from 'src/modules/networkProviders/networkProvidersService';
import fields from 'src/modules/networkProviders/importer/networkProvidersImporterFields';
import { i18n } from 'src/i18n';

const networkProvidersImporterActions = importerActions(
  'NETWORKPROVIDERS_IMPORTER',
  selectors,
  NetworkProvidersService.import,
  fields,
  i18n('entities.networkProviders.importer.fileName'),
  undefined,
  NetworkProvidersService.dataProvider,
);

export default networkProvidersImporterActions;