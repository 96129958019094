import list from 'src/modules/networkProviders/list/networkProvidersListReducers';
import form from 'src/modules/networkProviders/form/networkProvidersFormReducers';
import view from 'src/modules/networkProviders/view/networkProvidersViewReducers';
import destroy from 'src/modules/networkProviders/destroy/networkProvidersDestroyReducers';
import importerReducer from 'src/modules/networkProviders/importer/networkProvidersImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
