import { connectRouter } from 'connected-react-router';
import layout from 'src/modules/layout/layoutReducers';
import auth from 'src/modules/auth/authReducers';
import dashboards from 'src/modules/dashboards/dashboardsReducers';
import tenant from 'src/modules/tenant/tenantReducers';
import plan from 'src/modules/plan/planReducers';
import user from 'src/modules/user/userReducers';
import auditLog from 'src/modules/auditLog/auditLogReducers';
import settings from 'src/modules/settings/settingsReducers';
// import locations from 'src/modules/locations/locationsReducers';
// import buildings from 'src/modules/buildings/buildingsReducers';
// import floors from 'src/modules/floors/floorsReducers';
import assetTypes from 'src/modules/assetTypes/assetTypesReducers';
// import assetSubTypes from 'src/modules/assetSubTypes/assetSubTypesReducers';
// import telemetryKeys from 'src/modules/telemetryKeys/telemetryKeysReducers';
// import sensors from 'src/modules/sensors/sensorsReducers';
import assets from 'src/modules/assets/assetsReducers';
import orders from 'src/modules/orders/ordersReducers';
import orderItems from 'src/modules/orderItems/orderItemsReducers';
// import telemetry from 'src/modules/telemetry/telemetryReducers';
import notifications from 'src/modules/notifications/notificationsReducers';
// import sensorProfile from 'src/modules/sensorProfile/sensorProfileReducers';
// import gateways from 'src/modules/gateways/gatewaysReducers';
import userPermissions from 'src/modules/userPermissions/userPermissionsReducers';
import firmwares from 'src/modules/firmwares/firmwaresReducers';
import devices from 'src/modules/devices/devicesReducers';
import networkProviders from 'src/modules/networkProviders/networkProvidersReducers';

import { combineReducers } from 'redux';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    layout,
    auth,
    tenant,
    plan,
    user,
    auditLog,
    settings,
    // locations,
    // buildings,
    // floors,
    assetTypes,
    // assetSubTypes,
    // telemetryKeys,
    // sensors,
    assets,
    orders,
    orderItems,
    // telemetry,
    notifications,
    // sensorProfile,
    // gateways,
    userPermissions,
    dashboards,
    firmwares,
    devices,
    networkProviders,
  });
