import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  // {
  //   name: 'legacyId',
  //   label: i18n('entities.networkProviders.fields.legacyId'),
  //   schema: schemas.integer(
  //     i18n('entities.networkProviders.fields.legacyId'),
  //     {
  //       "required": true
  //     },
  //   ),
  // },
  // {
  //   name: 'name',
  //   label: i18n('entities.networkProviders.fields.name'),
  //   schema: schemas.string(
  //     i18n('entities.networkProviders.fields.name'),
  //     {
  //       "required": true
  //     },
  //   ),
  // },
  // {
  //   name: 'description',
  //   label: i18n('entities.networkProviders.fields.description'),
  //   schema: schemas.string(
  //     i18n('entities.networkProviders.fields.description'),
  //     {},
  //   ),
  // },
  // {
  //   name: 'device',
  //   label: i18n('entities.networkProviders.fields.device'),
  //   schema: schemas.relationToOne(
  //     i18n('entities.networkProviders.fields.device'),
  //     {},
  //   ),
  // },
  {
    name: 'msisdn',
    label: i18n('entities.networkProviders.fields.msisdn'),
    schema: schemas.string(
      i18n('entities.networkProviders.fields.msisdn'),
      {},
    ),
  },
  {
    name: 'iccid',
    label: i18n('entities.networkProviders.fields.iccid'),
    schema: schemas.string(
      i18n('entities.networkProviders.fields.iccid'),
      {},
    ),
  },
  {
    name: 'apn',
    label: i18n('entities.networkProviders.fields.apn'),
    schema: schemas.string(
      i18n('entities.networkProviders.fields.apn'),
      {},
    ),
  },
  {
    name: 'activatedAt',
    label: i18n('entities.networkProviders.fields.activatedAt'),
    schema: schemas.string(
      i18n('entities.networkProviders.fields.activatedAt'),
      {},
    ),
  },
  // {
  //   name: 'secrets',
  //   label: i18n('entities.networkProviders.fields.secrets'),
  //   schema: schemas.string(
  //     i18n('entities.networkProviders.fields.secrets'),
  //     {},
  //   ),
  // },
  // {
  //   name: 'config',
  //   label: i18n('entities.networkProviders.fields.config'),
  //   schema: schemas.string(
  //     i18n('entities.networkProviders.fields.config'),
  //     {},
  //   ),
  // },
  // {
  //   name: 'extra',
  //   label: i18n('entities.networkProviders.fields.extra'),
  //   schema: schemas.string(
  //     i18n('entities.networkProviders.fields.extra'),
  //     {},
  //   ),
  // },
];