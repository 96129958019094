import Roles from 'src/security/roles';
import Plans from 'src/security/plans';
import Storage from 'src/security/storage';

const storage = Storage.values;
const roles = Roles.values;
const plans = Plans.values;

class Permissions {
  static get values() {
    return {
      tenantEdit: {
        id: 'tenantEdit',
        allowedRoles: [roles.admin,],
         allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      tenantDestroy: {
        id: 'tenantDestroy',
        allowedRoles: [roles.admin,],
         allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      planEdit: {
        id: 'planEdit',
        allowedRoles: [roles.admin,],
         allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      planRead: {
        id: 'planRead',
        allowedRoles: [roles.admin,],
         allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userEdit: {
        id: 'userEdit',
        allowedRoles: [roles.admin, roles.org_admin, roles.local_admin, roles.org_FM_user, roles.local_FM_user, roles.user],
         allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userDestroy: {
        id: 'userDestroy',
        allowedRoles: [roles.admin, roles.org_admin],
         allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userCreate: {
        id: 'userCreate',
        allowedRoles: [roles.admin, roles.org_admin],
         allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userImport: {
        id: 'userImport',
        allowedRoles: [roles.admin, roles.org_admin],
         allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userRead: {
        id: 'userRead',
        allowedRoles: [roles.admin, roles.org_admin, roles.local_admin, roles.org_FM_user, roles.local_FM_user, roles.user],
         allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userAutocomplete: {
        id: 'userAutocomplete',
        allowedRoles: [roles.admin, roles.org_admin, roles.local_admin, roles.org_FM_user, roles.local_FM_user, roles.user],
         allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      auditLogRead: {
        id: 'auditLogRead',
        allowedRoles: [],
         allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      settingsEdit: {
        id: 'settingsEdit',
        allowedRoles: [roles.admin],
         allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [
          storage.settingsBackgroundImages,
          storage.settingsLogos,
        ],
      },
      // locationsImport: {
      //   id: 'locationsImport',
      //   allowedRoles: [roles.admin,roles.org_admin],
      //   allowedPlans: [plans.free, plans.growth, plans.enterprise],
      // },
      // locationsCreate: {
      //   id: 'locationsCreate',
      //   allowedRoles: [roles.admin,roles.org_admin],
      //   allowedPlans: [plans.free, plans.growth, plans.enterprise],
      //   allowedStorage: [

      //   ],
      // },
      // locationsEdit: {
      //   id: 'locationsEdit',
      //   allowedRoles: [roles.admin,roles.org_admin],
      //   allowedPlans: [plans.free, plans.growth, plans.enterprise],
      //   allowedStorage: [

      //   ],
      // },
      // locationsDestroy: {
      //   id: 'locationsDestroy',
      //   allowedRoles: [roles.admin,roles.org_admin],
      //   allowedPlans: [plans.free, plans.growth, plans.enterprise],
      //   allowedStorage: [

      //   ],
      // },
      // locationsRead: {
      //   id: 'locationsRead',
      //   allowedRoles: [roles.admin, roles.org_admin, roles.local_admin, roles.org_FM_user, roles.local_FM_user, roles.user],
      //   allowedPlans: [plans.free, plans.growth, plans.enterprise],
      // },
      // locationsAutocomplete: {
      //   id: 'locationsAutocomplete',
      //   allowedRoles: [roles.admin, roles.org_admin, roles.local_admin, roles.org_FM_user, roles.local_FM_user, roles.user],
      //   allowedPlans: [plans.free, plans.growth, plans.enterprise],
      // },

      // buildingsImport: {
      //   id: 'buildingsImport',
      //   allowedRoles: [roles.admin, roles.local_admin, roles.org_admin],
      //   allowedPlans: [plans.free, plans.growth, plans.enterprise],
      // },
      // buildingsCreate: {
      //   id: 'buildingsCreate',
      //   allowedRoles: [roles.admin, roles.local_admin, roles.org_admin],
      //   allowedPlans: [plans.free, plans.growth, plans.enterprise],
      //   allowedStorage: [
      //     storage.buildingsBuildingImages,
      //     storage.buildingsBuildingFiles,
      //   ],

      // },
      // buildingsEdit: {
      //   id: 'buildingsEdit',
      //   allowedRoles: [roles.admin, roles.local_admin, roles.org_admin],
      //   allowedPlans: [plans.free, plans.growth, plans.enterprise],
      //   allowedStorage: [
      //     storage.buildingsBuildingImages,
      //     storage.buildingsBuildingFiles,
      //   ],

      // },
      // buildingsDestroy: {
      //   id: 'buildingsDestroy',
      //   allowedRoles: [roles.admin, roles.local_admin, roles.org_admin],
      //   allowedPlans: [plans.free, plans.growth, plans.enterprise],
      //   allowedStorage: [
      //     storage.buildingsBuildingImages,
      //     storage.buildingsBuildingFiles,
      //   ],

      // },
      // buildingsRead: {
      //   id: 'buildingsRead',
      //   allowedRoles: [roles.admin, roles.org_admin, roles.local_admin, roles.org_FM_user, roles.local_FM_user, roles.user],
      //   allowedPlans: [plans.free, plans.growth, plans.enterprise],
      // },
      // buildingsAutocomplete: {
      //   id: 'buildingsAutocomplete',
      //   allowedRoles: [roles.admin, roles.org_admin, roles.local_admin, roles.org_FM_user, roles.local_FM_user, roles.user],
      //   allowedPlans: [plans.free, plans.growth, plans.enterprise],
      // },

      // floorsImport: {
      //   id: 'floorsImport',
      //   allowedRoles: [roles.admin, roles.local_admin, roles.org_admin],
      //   allowedPlans: [plans.free, plans.growth, plans.enterprise],
      // },
      // floorsCreate: {
      //   id: 'floorsCreate',
      //   allowedRoles: [roles.admin, roles.local_admin, roles.org_admin],
      //   allowedPlans: [plans.free, plans.growth, plans.enterprise],
      //   allowedStorage: [

      //   ],
      // },
      // floorsEdit: {
      //   id: 'floorsEdit',
      //   allowedRoles: [roles.admin, roles.local_admin, roles.org_admin],
      //   allowedPlans: [plans.free, plans.growth, plans.enterprise],
      //   allowedStorage: [

      //   ],
      // },
      // floorsDestroy: {
      //   id: 'floorsDestroy',
      //   allowedRoles: [roles.admin, roles.local_admin, roles.org_admin],
      //   allowedPlans: [plans.free, plans.growth, plans.enterprise],
      //   allowedStorage: [

      //   ],
      // },
      // floorsRead: {
      //   id: 'floorsRead',
      //   allowedRoles: [roles.admin, roles.org_admin, roles.local_admin, roles.org_FM_user, roles.local_FM_user, roles.user],
      //   allowedPlans: [plans.free, plans.growth, plans.enterprise],
      // },
      // floorsAutocomplete: {
      //   id: 'floorsAutocomplete',
      //   allowedRoles: [roles.admin, roles.org_admin, roles.local_admin, roles.org_FM_user, roles.local_FM_user, roles.user],
      //   allowedPlans: [plans.free, plans.growth, plans.enterprise],
      // },

      assetTypesImport: {
        id: 'assetTypesImport',
        allowedRoles: [],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      assetTypesCreate: {
        id: 'assetTypesCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.assetsTypeIcon,
        ],
      },
      assetTypesEdit: {
        id: 'assetTypesEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.assetsTypeIcon,
        ],
      },
      assetTypesDestroy: {
        id: 'assetTypesDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.assetsTypeIcon,
        ],
      },
      assetTypesRead: {
        id: 'assetTypesRead',
        allowedRoles: [roles.admin, roles.org_admin, roles.local_admin, roles.org_FM_user, roles.local_FM_user],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      assetTypesAutocomplete: {
        id: 'assetTypesAutocomplete',
        allowedRoles: [roles.admin, roles.org_admin, roles.local_admin, roles.org_FM_user, roles.local_FM_user, roles.user],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      // assetSubTypesImport: {
      //   id: 'assetSubTypesImport',
      //   allowedRoles: [roles.admin],
      //   allowedPlans: [plans.free, plans.growth, plans.enterprise],
      // },
      // assetSubTypesCreate: {
      //   id: 'assetSubTypesCreate',
      //   allowedRoles: [roles.admin],
      //   allowedPlans: [plans.free, plans.growth, plans.enterprise],
      //   allowedStorage: [

      //   ],
      // },
      // assetSubTypesEdit: {
      //   id: 'assetSubTypesEdit',
      //   allowedRoles: [roles.admin],
      //   allowedPlans: [plans.free, plans.growth, plans.enterprise],
      //   allowedStorage: [

      //   ],
      // },
      // assetSubTypesDestroy: {
      //   id: 'assetSubTypesDestroy',
      //   allowedRoles: [roles.admin],
      //   allowedPlans: [plans.free, plans.growth, plans.enterprise],
      //   allowedStorage: [

      //   ],
      // },
      // assetSubTypesRead: {
      //   id: 'assetSubTypesRead',
      //   allowedRoles: [roles.admin, roles.org_admin, roles.local_admin, roles.org_FM_user, roles.local_FM_user],
      //   allowedPlans: [plans.free, plans.growth, plans.enterprise],
      // },
      // assetSubTypesAutocomplete: {
      //   id: 'assetSubTypesAutocomplete',
      //   allowedRoles: [roles.admin, roles.org_admin, roles.local_admin, roles.org_FM_user, roles.local_FM_user, roles.user],
      //   allowedPlans: [plans.free, plans.growth, plans.enterprise],
      // },

      // telemetryKeysImport: {
      //   id: 'telemetryKeysImport',
      //   allowedRoles: [roles.admin],
      //   allowedPlans: [plans.free, plans.growth, plans.enterprise],
      // },
      // telemetryKeysCreate: {
      //   id: 'telemetryKeysCreate',
      //   allowedRoles: [roles.admin],
      //   allowedPlans: [plans.free, plans.growth, plans.enterprise],
      //   allowedStorage: [

      //   ],
      // },
      // telemetryKeysEdit: {
      //   id: 'telemetryKeysEdit',
      //   allowedRoles: [roles.admin],
      //   allowedPlans: [plans.free, plans.growth, plans.enterprise],
      //   allowedStorage: [

      //   ],
      // },
      // telemetryKeysDestroy: {
      //   id: 'telemetryKeysDestroy',
      //   allowedRoles: [roles.admin],
      //   allowedPlans: [plans.free, plans.growth, plans.enterprise],
      //   allowedStorage: [

      //   ],
      // },
      // telemetryKeysRead: {
      //   id: 'telemetryKeysRead',
      //   allowedRoles: [roles.admin, roles.user],
      //   allowedPlans: [plans.free, plans.growth, plans.enterprise],
      // },
      // telemetryKeysAutocomplete: {
      //   id: 'telemetryKeysAutocomplete',
      //   allowedRoles: [roles.admin, roles.user],
      //   allowedPlans: [plans.free, plans.growth, plans.enterprise],
      // },

      // sensorsImport: {
      //   id: 'sensorsImport',
      //   allowedRoles: [roles.admin],
      //   allowedPlans: [plans.free, plans.growth, plans.enterprise],
      // },
      // sensorsCreate: {
      //   id: 'sensorsCreate',
      //   allowedRoles: [roles.admin],
      //   allowedPlans: [plans.free, plans.growth, plans.enterprise],
      //   allowedStorage: [

      //   ],
      // },
      // sensorsEdit: {
      //   id: 'sensorsEdit',
      //   allowedRoles: [roles.admin],
      //   allowedPlans: [plans.free, plans.growth, plans.enterprise],
      //   allowedStorage: [

      //   ],
      // },
      // sensorsDestroy: {
      //   id: 'sensorsDestroy',
      //   allowedRoles: [roles.admin],
      //   allowedPlans: [plans.free, plans.growth, plans.enterprise],
      //   allowedStorage: [

      //   ],
      // },
      // sensorsRead: {
      //   id: 'sensorsRead',
      //   allowedRoles: [roles.admin, roles.org_admin, roles.local_admin, roles.org_FM_user, roles.local_FM_user],
      //   allowedPlans: [plans.free, plans.growth, plans.enterprise],
      // },
      // sensorsAutocomplete: {
      //   id: 'sensorsAutocomplete',
      //   allowedRoles: [roles.admin, roles.org_admin, roles.local_admin, roles.org_FM_user, roles.local_FM_user],
      //   allowedPlans: [plans.free, plans.growth, plans.enterprise],
      // },

      assetsImport: {
        id: 'assetsImport',
        allowedRoles: [],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      assetsCreate: {
        id: 'assetsCreate',
        allowedRoles: [roles.admin, roles.local_admin, roles.org_admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.assetsIcon,
        ],
      },
      assetsEdit: {
        id: 'assetsEdit',
        allowedRoles: [roles.admin, roles.local_admin, roles.org_admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.assetsIcon,
        ],
      },
      assetsDestroy: {
        id: 'assetsDestroy',
        allowedRoles: [roles.admin, roles.local_admin, roles.org_admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.assetsIcon,
        ],
      },
      assetsRead: {
        id: 'assetsRead',
        allowedRoles: [roles.admin, roles.org_admin, roles.local_admin, roles.org_FM_user, roles.local_FM_user, roles.user],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      assetsAutocomplete: {
        id: 'assetsAutocomplete',
        allowedRoles: [roles.admin, roles.org_admin, roles.local_admin, roles.org_FM_user, roles.local_FM_user, roles.user],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      ordersImport: {
        id: 'ordersImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      ordersCreate: {
        id: 'ordersCreate',
        allowedRoles: [roles.admin,  roles.org_admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      ordersEdit: {
        id: 'ordersEdit',
        allowedRoles: [roles.admin,  roles.org_admin, roles.user],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      ordersDestroy: {
        id: 'ordersDestroy',
        allowedRoles: [roles.admin,  roles.org_admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      ordersRead: {
        id: 'ordersRead',
        allowedRoles: [roles.admin, roles.org_admin, roles.user],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      ordersAutocomplete: {
        id: 'ordersAutocomplete',
        allowedRoles: [roles.admin, roles.org_admin, roles.user],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      orderItemsImport: {
        id: 'orderItemsImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      orderItemsCreate: {
        id: 'orderItemsCreate',
        allowedRoles: [roles.admin,  roles.org_admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      orderItemsEdit: {
        id: 'orderItemsEdit',
        allowedRoles: [roles.admin,  roles.org_admin, roles.user],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      orderItemsDestroy: {
        id: 'orderItemsDestroy',
        allowedRoles: [roles.admin,  roles.org_admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      orderItemsRead: {
        id: 'orderItemsRead',
        allowedRoles: [roles.admin, roles.org_admin, roles.user],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      orderItemsAutocomplete: {
        id: 'orderItemsAutocomplete',
        allowedRoles: [roles.admin, roles.org_admin, roles.user],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      // telemetryImport: {
      //   id: 'telemetryImport',
      //   allowedRoles: [roles.admin],
      //   allowedPlans: [plans.free, plans.growth, plans.enterprise],
      // },
      // telemetryCreate: {
      //   id: 'telemetryCreate',
      //   allowedRoles: [roles.admin],
      //   allowedPlans: [plans.free, plans.growth, plans.enterprise],
      //   allowedStorage: [

      //   ],
      // },
      // telemetryEdit: {
      //   id: 'telemetryEdit',
      //   allowedRoles: [roles.admin],
      //   allowedPlans: [plans.free, plans.growth, plans.enterprise],
      //   allowedStorage: [

      //   ],
      // },
      // telemetryDestroy: {
      //   id: 'telemetryDestroy',
      //   allowedRoles: [roles.admin],
      //   allowedPlans: [plans.free, plans.growth, plans.enterprise],
      //   allowedStorage: [

      //   ],
      // },
      // telemetryRead: {
      //   id: 'telemetryRead',
      //   allowedRoles: [roles.admin, roles.user],
      //   allowedPlans: [plans.free, plans.growth, plans.enterprise],
      // },
      // telemetryAutocomplete: {
      //   id: 'telemetryAutocomplete',
      //   allowedRoles: [roles.admin, roles.user],
      //   allowedPlans: [plans.free, plans.growth, plans.enterprise],
      // },

      notificationsImport: {
        id: 'notificationsImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      notificationsCreate: {
        id: 'notificationsCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      notificationsEdit: {
        id: 'notificationsEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      notificationsDestroy: {
        id: 'notificationsDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      notificationsRead: {
        id: 'notificationsRead',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      notificationsAutocomplete: {
        id: 'notificationsAutocomplete',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      // sensorProfileImport: {
      //   id: 'sensorProfileImport',
      //   allowedRoles: [roles.admin],
      //   allowedPlans: [plans.free, plans.growth, plans.enterprise],
      // },
      // sensorProfileCreate: {
      //   id: 'sensorProfileCreate',
      //   allowedRoles: [roles.admin],
      //   allowedPlans: [plans.free, plans.growth, plans.enterprise],
      //   allowedStorage: [

      //   ],
      // },
      // sensorProfileEdit: {
      //   id: 'sensorProfileEdit',
      //   allowedRoles: [roles.admin],
      //   allowedPlans: [plans.free, plans.growth, plans.enterprise],
      //   allowedStorage: [

      //   ],
      // },
      // sensorProfileDestroy: {
      //   id: 'sensorProfileDestroy',
      //   allowedRoles: [roles.admin],
      //   allowedPlans: [plans.free, plans.growth, plans.enterprise],
      //   allowedStorage: [

      //   ],
      // },
      // sensorProfileRead: {
      //   id: 'sensorProfileRead',
      //   allowedRoles: [roles.admin, roles.org_admin, roles.local_admin],
      //   allowedPlans: [plans.free, plans.growth, plans.enterprise],
      // },
      // sensorProfileAutocomplete: {
      //   id: 'sensorProfileAutocomplete',
      //   allowedRoles: [roles.admin, roles.org_admin, roles.local_admin],
      //   allowedPlans: [plans.free, plans.growth, plans.enterprise],
      // },
      // gatewaysImport: {
      //   id: 'gatewaysImport',
      //   allowedRoles: [roles.admin],
      //   allowedPlans: [plans.free, plans.growth, plans.enterprise],
      // },
      // gatewaysCreate: {
      //   id: 'gatewaysCreate',
      //   allowedRoles: [roles.admin],
      //   allowedPlans: [plans.free, plans.growth, plans.enterprise],
      //   allowedStorage: [

      //   ],
      // },
      // gatewaysEdit: {
      //   id: 'gatewaysEdit',
      //   allowedRoles: [roles.admin],
      //   allowedPlans: [plans.free, plans.growth, plans.enterprise],
      //   allowedStorage: [

      //   ],
      // },
      // gatewaysDestroy: {
      //   id: 'gatewaysDestroy',
      //   allowedRoles: [roles.admin],
      //   allowedPlans: [plans.free, plans.growth, plans.enterprise],
      //   allowedStorage: [

      //   ],
      // },
      // gatewaysRead: {
      //   id: 'gatewaysRead',
      //   allowedRoles: [roles.admin, roles.org_admin, roles.local_admin, roles.org_FM_user, roles.local_FM_user],
      //   allowedPlans: [plans.free, plans.growth, plans.enterprise],
      // },
      // gatewaysAutocomplete: {
      //   id: 'gatewaysAutocomplete',
      //   allowedRoles: [roles.admin, roles.org_admin, roles.local_admin, roles.org_FM_user, roles.local_FM_user],
      //   allowedPlans: [plans.free, plans.growth, plans.enterprise],
      // },

      userPermissionsImport: {
        id: 'userPermissionsImport',
        allowedRoles: [roles.admin, roles.org_admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      userPermissionsCreate: {
        id: 'userPermissionsCreate',
        allowedRoles: [roles.admin, roles.org_admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      userPermissionsEdit: {
        id: 'userPermissionsEdit',
        allowedRoles: [roles.admin, roles.org_admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      userPermissionsDestroy: {
        id: 'userPermissionsDestroy',
        allowedRoles: [roles.admin, roles.org_admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      userPermissionsRead: {
        id: 'userPermissionsRead',
        allowedRoles: [roles.admin, roles.org_admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      userPermissionsAutocomplete: {
        id: 'userPermissionsAutocomplete',
        allowedRoles: [roles.admin, roles.org_admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      firmwaresImport: {
        id: 'firmwaresImport',
        allowedRoles: [],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      firmwaresCreate: {
        id: 'firmwaresCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.firmwarePackage,
        ],
      },
      firmwaresEdit: {
        id: 'firmwaresEdit',
        allowedRoles: [roles.admin,],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.firmwarePackage,
        ],
      },
      firmwaresDestroy: {
        id: 'firmwaresDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.firmwarePackage,
        ],
      },
      firmwaresRead: {
        id: 'firmwaresRead',
        allowedRoles: [roles.admin, roles.org_admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      firmwaresAutocomplete: {
        id: 'firmwaresAutocomplete',
        allowedRoles: [roles.admin, roles.org_admin,],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      devicesImport: {
        id: 'devicesImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      devicesCreate: {
        id: 'devicesCreate',
        allowedRoles: [roles.admin,],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          // storage.devicesIcon,
        ],
      },
      devicesEdit: {
        id: 'devicesEdit',
        allowedRoles: [roles.admin,],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          // storage.devicesIcon,
        ],
      },
      devicesDestroy: {
        id: 'devicesDestroy',
        allowedRoles: [roles.admin,],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          // storage.devicesIcon,
        ],
      },
      devicesRead: {
        id: 'devicesRead',
        allowedRoles: [roles.admin,],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      devicesAutocomplete: {
        id: 'devicesAutocomplete',
        allowedRoles: [roles.admin,],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      networkProvidersImport: {
        id: 'networkProvidersImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      networkProvidersCreate: {
        id: 'networkProvidersCreate',
        allowedRoles: [roles.admin,],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          // storage.networkProvidersIcon,
        ],
      },
      networkProvidersEdit: {
        id: 'networkProvidersEdit',
        allowedRoles: [roles.admin,],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          // storage.networkProvidersIcon,
        ],
      },
      networkProvidersDestroy: {
        id: 'networkProvidersDestroy',
        allowedRoles: [roles.admin,],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          // storage.networkProvidersIcon,
        ],
      },
      networkProvidersRead: {
        id: 'networkProvidersRead',
        allowedRoles: [roles.admin,],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      networkProvidersAutocomplete: {
        id: 'networkProvidersAutocomplete',
        allowedRoles: [roles.admin,],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

    };
  }

  static get asArray() {
    return Object.keys(this.values).map((value) => {
      return this.values[value];
    });
  }
}

export default Permissions;
