import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/devices/importer/devicesImporterSelectors';
import DevicesService from 'src/modules/devices/devicesService';
import fields from 'src/modules/devices/importer/devicesImporterFields';
import { i18n } from 'src/i18n';

const devicesImporterActions = importerActions(
  'DEVICES_IMPORTER',
  selectors,
  DevicesService.import,
  fields,
  i18n('entities.devices.importer.fileName'),
);

export default devicesImporterActions;